import request from "../utils/request";

export const register = (data?: any) => {
  return request({
    url: "lemoUserServer/register/pcAdd",
    method: "post",
    data
  });
};

export const getArea = (data?: any) => {
  return request({
    url: "lemoCommonServer/getArea",
    method: "post",
    data
  });
};

export const getDictListByKey = (data?: any) => {
  return request({
    url: "lemoCommonServer/getDictListByKey",
    method: "post",
    data
  });
};

export const uploadFile = (data?: any) => {
  return request({
    url: "lemoCommonServer/uploadFile",
    method: "post",
    data
  });
};

export const getSpinfoListByArea = (data?: any) => {
  return request({
    url: "lemoCommonServer/getSpinfoListByArea",
    method: "post",
    data
  });
};
