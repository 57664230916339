export const getMode = () => {
  return process.env.VUE_APP_MODE;
};

export const getCookieString = (name: any, str: string) => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const r = str.match(reg);
  if (r != null) return unescape(r[2]);
  return null;
};

export const setCookie = (name: string, val: string, day = 365) => {
  const date = new Date();
  let expires = "";
  if (day == Infinity) {
    expires = new Date("Fri, 31 Dec 9999 23:59:59 GMT").toUTCString();
  } else if (day < 0) {
    date.setTime(date.getTime() - 1);
    expires = date.toUTCString();
  } else {
    date.setTime(date.getTime() + day * 24 * 60 * 60 * 1000);
    expires = date.toUTCString();
  }

  window.document.cookie = `${name}=${val};expires=${expires};path=/`;
};

export const getCookie = (name: string) => {
  if (document.cookie.length > 0) {
    const decodedCookie = decodeURIComponent(document.cookie);
    const query = decodedCookie.split("; ");

    for (let i = 0; i < query.length; i++) {
      const val = query[i];
      return getCookieString(name, val) || "";
    }
  }
};

export const clearCookie = (name: string) => {
  setCookie(name, "", -1);
};
