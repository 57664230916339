import axios from "axios";
import { Message } from "element-ui";
import qs from "qs";
import { getMode } from "@/utils/utils";

const hasError = (res: any) => {
  return Number(res.code) === 0;
};

console.log(getMode());

// let baseUrl = "";
// if (getMode() === "production") {
//   baseUrl = "http://39.106.69.73:8089/";
// } else if (getMode() === "test") {
//   baseUrl = "http://39.106.69.73:8089/";
// } else {
//   baseUrl = "http://39.106.69.73:8089/";
// }

const service = axios.create({
  baseURL: "https://sys.lemonstea.com/", // api的base_url
  timeout: 20000 // 请求超时时间
});

service.interceptors.request.use(
  (config: any) => {
    if (
      config.data &&
      Object.prototype.toString.call(config.data).includes("FormData")
    ) {
      Object.assign(config.headers, {
        "Content-Type": "multipart/form-data"
      });
    } else {
      if (config.method === "post") {
        config.data = qs.stringify(config.data);
      }
    }
    return config;
  },
  (err: any) => {
    Promise.reject(err);
  }
);

service.interceptors.response.use(
  (response: any) => {
    const { data } = response;

    if (hasError(response.data)) {
      return Promise.resolve(data);
    } else {
      return Promise.reject(data);
    }
  },
  (err: any) => {
    Message({
      message: err.msg || "请求错误",
      type: "error"
    });
    return Promise.reject(err.response);
  }
);

export default service;
