





































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import {
  getArea,
  getDictListByKey,
  getSpinfoListByArea,
  register,
  uploadFile
} from "@/api/api";

@Component({
  components: {
    Header
  }
})
export default class Login extends Vue {
  formRule: Parms = {
    password: {
      rule: /^.{6,}$/,
      msg: "密码不少于6位"
    },
    itaxno: {
      rule: /^.{18}$/,
      msg: "统一社会信用代码长度不正确"
    }
  };
  loading = false;
  password = "";
  applyForm: Parms = {
    loginName: "",
    password: "",
    spname: "",
    itaxno: "",
    blpath: "",
    contactsUserName: "",
    phonenumber: "",
    sptype: "",
    province: "",
    city: "",
    area: "",
    parentspno: "",
    // ismember: "",
    splevel: "2"
  };
  provinceData: Parms = {};
  cityData: Parms = {};
  areaData: Parms = {};
  typeList: any[] = [];
  levelList: any[] = [];
  sLevelList: any[] = [];
  areaServiceList: any[] = [];
  previewImg = false;

  get formVerify() {
    return Object.values(this.applyForm).every(el => {
      return el;
    });
  }

  @Watch("applyForm.province")
  changeProvince() {
    this.applyForm.city = "";
    this.applyForm.area = "";
    this.getArea({
      pcode: this.applyForm.province
    }).then((data: any) => {
      this.cityData = data && data.rows;
    });
  }

  @Watch("applyForm.city")
  changeCity() {
    this.applyForm.area = "";
    this.getArea({
      pcode: this.applyForm.city
    }).then((data: any) => {
      this.areaData = data && data.rows;
    });
  }

  created() {
    // 获取城市字典
    this.getArea().then((data: any) => {
      this.provinceData = data && data.rows;
    });
    // 获取服务商类型
    this.getDictListByKey("t_spinfo_type").then((data: any) => {
      this.typeList = data.rows;
    });
    // 获取协会会员等级
    this.getDictListByKey("t_spinfo_member_level").then((data: any) => {
      this.levelList = data.rows;
    });
    // 获取服务商级别
    // this.getDictListByKey("t_spinfo_level").then((data: any) => {
    //   this.sLevelList = data.rows;
    // });
  }

  getArea(data?: object) {
    return getArea(data).then(data => {
      return data;
    });
  }

  getDictListByKey(type: string) {
    return getDictListByKey({
      dictType: type
    }).then(data => {
      return data;
    });
  }

  selectProvince() {
    this.getSpinfoListByArea(this.applyForm.area);
  }

  selectCity() {
    this.getSpinfoListByArea(this.applyForm.city);
  }

  selectArea() {
    this.getSpinfoListByArea(this.applyForm.area);
  }

  getSpinfoListByArea(code: any) {
    getSpinfoListByArea({
      citycode: code
    }).then((data: any) => {
      this.areaServiceList = data.rows;
    });
  }

  sumbit() {
    if (this.password !== this.applyForm.password) {
      this.$message.warning("两次密码不相同");
      return;
    }
    for (const key in this.formRule) {
      if (!this.formRule[key].rule.test(this.applyForm[key])) {
        this.$message.warning(this.formRule[key].msg);
        return;
      }
    }
    this.loading = true;
    register(this.applyForm).then(
      () => {
        this.loading = false;
        this.$message.success("注册成功");
      },
      err => {
        this.loading = false;
        this.$message.warning(err.msg || "请求错误");
      }
    );
  }

  uploadImg() {
    (this.$refs.imagePicker as any).click();
  }

  changeImg(e: any) {
    e.preventDefault();
    const files: any = e.target.files[0];

    const size = files && files.size / 1024 / 1024;
    if (size > 3) {
      this.$message.warning("上传图片请勿大于3M");
      return;
    }

    this.requestUploadImg(files);
  }

  requestUploadImg(file: any) {
    const param = new FormData();
    param.append("image", file);

    this.loading = true;
    uploadFile(param).then(
      data => {
        this.applyForm.blpath = data.data.blpath;
        console.log(data);
        this.applyForm = Object.assign({}, this.applyForm, data.data);
        this.loading = false;
      },
      err => {
        this.loading = false;
        this.$message.warning(err.msg || "请求错误");
      }
    );
  }
}
